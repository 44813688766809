<template>
  <div>
    <graphTitle class="title" :title="'出入场车流量分析'">
      <el-tooltip
        placement="top"
        content="展示所选日期内各时段的车辆出入场数量，用来分析一天内入场车流量与出场车流量的变化情况"
      >
        <i class="el-icon-question my-icon" />
      </el-tooltip>
    </graphTitle>
    <div id="circularChart" class="circularChart"></div>
  </div>
</template>
<script>
// import hub from "./../../common/js/hub"
import graphTitle from "@/components/graphTitle";

export default {
  props: {
    tableData: {
      // type: Array,
      default: function () {
        return {};
      },
    },
    arrearageHistorytableData: {
      default: function () {
        return {};
      },
    },
    temporaryParktableData: {
      default: function () {
        return {};
      },
    },
    nightParktableData: {
      default: function () {
        return {};
      },
    },
    paySummaryReporttableData: {
      default: function () {
        return {};
      },
    },
    type: {
      default: "c3",
    },
  },
  data() {
    return {
      totalNum: 0,
      nameList: [],
      dataList: [],
      // 日期（共用）
      dateList: [],
      // 夜间停车 （以下）
      nightParknameList: [],
      nightParkdataList: [],
      // 营收总报表（以下）
      paySummaryReportdataList: [],
      seriesValue1: [
        {
          name: "应收总额",
          type: "line",
          data: [0, 0, 0, 0, 0, 0, 0, 0],
          lineStyle: {
            color: "#00FF00",
          },
          itemStyle: {
            normal: {
              color: "#00FF00",
            },
          },
        },
        {
          name: "欠费额",
          type: "line",
          data: [0, 0, 0, 0, 0, 0, 0, 0],
          lineStyle: {
            color: "red",
          },
          itemStyle: {
            normal: {
              color: "red",
            },
          },
        },
      ],
      seriesValue2: [
        {
          name: "当日临停次数",
          type: "line",
          data: [0, 0, 0, 0, 0, 0, 0, 0],
          lineStyle: {
            color: "#00FF00",
          },
          itemStyle: {
            normal: {
              color: "#00FF00",
            },
          },
        },
      ],
      seriesValue3: [
        {
          name: "入场车次",
          type: "line",
          data: [],
          lineStyle: {
            color: "#FF3300",
          },
          itemStyle: {
            normal: {
              color: "#FF3300",
            },
          },
        },
        {
          name: "出场车次",
          type: "line",
          data: [],
          lineStyle: {
            color: "#9933CC",
          },
          itemStyle: {
            normal: {
              color: "#9933CC",
            },
          },
        },
      ],
      seriesValue4: [
        {
          name: "历史营收趋势",
          type: "line",
          data: [],
          lineStyle: {
            color: "#00FF00",
          },
          itemStyle: {
            normal: {
              color: "#00FF00",
            },
          },
        },
      ],
      textTitle1: "欠费金额趋势",
      textTitle2: "过去30日临时停车趋势",
      // textTitle3: '日间/夜间停车时长趋势',
      textTitle3: "",
      textTitle4: "历史营收趋势",
    };
  },
  components: {
    graphTitle,
  },
  watch: {
    tableData: {
      handler(value) {
        this.totalNum = 0;
        this.dataList = [];
        this.nameList = [];
        for (var i = 0; i < value.length; i++) {
          this.totalNum += value[i].value * 1;
          this.nameList.push(value[i].dataDate);
          this.dataList.push({
            name: value[i].name,
            value: Number(value[i].value / 100).toFixed(2),
          });
        }
        this.totalNum = (this.totalNum / 100).toFixed(2);
        this.drawGraph();
      },
      deep: true,
    },
    // 欠费历史分析（欠费金额趋势）
    arrearageHistorytableData: {
      handler(value) {
        this.dateList = [];
        this.seriesValue1[0].data = [];
        this.seriesValue1[1].data = [];
        for (var i = 0; i < value.length; i++) {
          //        this.totalNum += value[i].value * 1
          this.dateList.push(value[i].dataDate);
          // 应收金额
          this.seriesValue1[0].data.push(Number(value[i].shouldPay / 100).toFixed(2));
          // 欠费额
          this.seriesValue1[1].data.push(Number(value[i].arrearageAmount / 100).toFixed(2));
        }
        this.drawGraph();
      },
      deep: true,
    },
    // 临时停车统计（过去30日临时停车趋势）
    temporaryParktableData: {
      handler(value) {
        this.dateList = [];
        this.seriesValue2[0].data = [];
        for (var i = 0; i < value.length; i++) {
          //        this.totalNum += value[i].value * 1
          this.dateList.push(value[i].dataDate);
          // 过去30日临时停车趋势数据
          this.seriesValue2[0].data.push(value[i].shortParkNum);
        }
        this.drawGraph();
      },
      deep: true,
    },
    // 夜间停车记录趋势
    nightParktableData: {
      handler(value) {
        // console.log(value)
        this.totalNum = 0;
        this.nightParknameList = [];
        this.dateList = [];
        this.seriesValue3[0].data = [];
        this.seriesValue3[1].data = [];
        // for (var i = value.length - 1; i > 0; i--) {
        // for (var i = value.length - 1; i >= 0; i--) {
        //         for (var i = 0; i < value.length; i++) {
        // //        this.totalNum += value[i].value * 1
        //           this.dateList.push(value[i].HOUR);
        //           // 入场车辆
        //           this.seriesValue3[0].data.push(value[i].parkIn);
        //           // 出场车辆
        //           this.seriesValue3[1].data.push(value[i].parkOut);
        //         }
        for (var x in value.parkIn) {
          // console.log(x + "=" + value.parkTime[x]);
          // if (x % 2 == 0) {
          this.seriesValue3[0].data.push(value.parkIn[x]);
          // }
        }
        for (var b in value.parkOut) {
          // if (b % 2 == 0) {
          this.seriesValue3[1].data.push(value.parkOut[b]);
          // }
        }
        this.drawGraph();
      },
      deep: true,
    },
    // 营收总报表的营收趋势
    paySummaryReporttableData: {
      handler(value) {
        this.totalNum = 0;
        let dateArr = [];
        this.dateList = [];
        this.seriesValue4[0].data = [];
        for (var i = 0; i < value.length; i++) {
          // this.totalNum += value[i].value * 1
          dateArr.push(value[i].date);
          this.seriesValue4[0].data.push(Number(value[i].amount / 100).toFixed(2));
        }
        this.dateList = this.uniq(dateArr);
        this.drawGraph();
      },
      deep: true,
    },
  },
  methods: {
    // 去重
    uniq(array) {
      var temp = [];
      for (var i = 0; i < array.length; i++) {
        if (temp.indexOf(array[i]) == -1) {
          temp.push(array[i]);
        }
      }
      return temp;
    },
    drawGraph() {
      let that = this;
      let circularChart = this.$echarts.init(document.getElementById("circularChart"));
      let option = {
        title: {
          text:
            that.type == "c1"
              ? that.textTitle1
              : that.type == "c2"
              ? that.textTitle2
              : that.type == "c3"
              ? that.textTitle3
              : that.textTitle4,
        },
        legend: {
          data:
            that.type == "c1"
              ? ["应收总额", "欠费额"]
              : that.type == "c2"
              ? ["当日临停次数"]
              : that.type == "c3"
              ? ["入场车次", "出场车次"]
              : ["历史营收趋势"],
        },
        tooltip: {
          trigger: "axis",
          formatter(params) {
            let str = "";
            str += params[0].axisValue + ":00-" + params[0].axisValue.split(":")[0] + ":59:59<br/>";
            params.forEach((e) => {
              str += e.marker + `${e.seriesName}:${e.value ? e.value : 0}(次)<br/>`;
            });
            return str;
          },
        },
        toolbox: {
          show: true,
          feature: {
            //		            magicType: {show: true, type: ['stack', 'tiled']},
            //		            saveAsImage: {show: true}
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          // data: this.dateList
          data: [
            "00:00",
            "01:00",
            "02:00",
            "03:00",
            "04:00",
            "05:00",
            "06:00",
            "07:00",
            "08:00",
            "09:00",
            "10:00",
            "11:00",
            "12:00",
            "13:00",
            "14:00",
            "15:00",
            "16:00",
            "17:00",
            "18:00",
            "19:00",
            "20:00",
            "21:00",
            "22:00",
            "23:00",
          ],
        },
        yAxis: {
          type: "value",
          name: "车次数(次)",
        },
        series:
          that.type == "c1"
            ? that.seriesValue1
            : that.type == "c2"
            ? that.seriesValue2
            : that.type == "c3"
            ? that.seriesValue3
            : that.seriesValue4,
      };
      circularChart.setOption(option, true);
    },
  },
  mounted() {
    this.drawGraph();
  },
  created() {},
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.circularChart {
  width: 100%;
  height: 300px;
  overflow: hidden;
}
</style>
