<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper marginB20" @keydown.enter.prevent="getData">
        <el-form :inline="true" label-position="right" :model="formInline" class="demo-form-inline">
          <!-- <el-form-item :label="$t('searchModule.region')">
            <el-select v-model.trim="areaId" filterable placeholder="请选择" @change="areaChange">
              <el-option label="全部" value></el-option>
              <el-option
                :label="area.areaName"
                :value="area.areaId"
                :key="area.areaId"
                v-for="area in areaList"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.region')">
            <el-select v-model.trim="streetId" @change="streetChange" filterable placeholder="请选择">
              <el-option label="全部" value></el-option>
              <el-option
                :label="area.areaName"
                :value="area.areaId"
                :key="area.areaId"
                v-for="area in streetList"
              ></el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item :label="$t('searchModule.region')" prop="areaId">
            <a-cascader ref="cascader" @change="parkClear"></a-cascader>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Belonging_operator')">
            <a-operation-select ref="operationSelect" @change="parkClear"></a-operation-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Type_of_parking_lot')">
            <a-park-type-select ref="parkTypeSelect" @change="parkClear"></a-park-type-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Road_or_Parking_Lot')">
            <a-park-select
              ref="parkSelect"
              :instance="this"
              parkTypeRefName="parkTypeSelect"
              operationRefName="operationSelect"
              areaRefName="cascader"
            ></a-park-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Date_search')">
            <a-date-picker ref="datePicker" :selectkeys="selectkeys"></a-date-picker>
          </el-form-item>
          <!-- <el-form-item :label="$t('searchModule.date')" prop="date">
            <el-date-picker
              v-model="formInline.date"
              type="date"
              :picker-options="pickerOptions"
              placeholder="选择日期"
            ></el-date-picker>
          </el-form-item> -->
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="
                page = 1;
                getData();
              "
              :loading="loading"
              v-if="$route.meta.authority.button.query"
              >{{ $t('button.search') }}</el-button
            >
            <el-button type="primary" icon="el-icon-refresh" @click="empty()" :loading="loading"
              >{{ $t('button.reset') }}</el-button
            >
            <!-- <el-button type="success" icon="el-icon-upload2" v-if='$route.meta.authority.button.export'
                       @click="exportFile()">导出
            </el-button>-->
          </el-form-item>
        </el-form>
      </div>
      <div class="timeBox bgFFF">
        <div class style="width: 100%; height: 40px">
          <el-col :span="8">
            <div class="title">停车高峰时段</div>
          </el-col>
          <el-col :span="8">
            <div class="title">入场压力时段</div>
          </el-col>
          <el-col :span="8">
            <div class="title">出场压力时段</div>
          </el-col>
        </div>
      </div>
      <el-row :gutter="20">
        <el-col :span="8">
          <div class="bgFFF">
            <el-tooltip
              placement="top"
              :content="comHour(this.timeInterval.peakPeriod, '暂无停车高峰时段')"
            >
              <div class="Colorbox">
                <h2 v-html="comHour(this.timeInterval.peakPeriod, '暂无停车高峰时段')"></h2>
              </div>
            </el-tooltip>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="bgFFF">
            <div class="Colorbox" style="background: #0f6eff">
              <h2 v-html="comHour(this.timeInterval.entryLoadRatePeriod, '暂无入场压力时段')"></h2>
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="bgFFF">
            <div class="Colorbox" style="background: #008000">
              <h2 v-html="comHour(this.timeInterval.exitLoadRatePeriod, '暂无出场压力时段')"></h2>
            </div>
          </div>
        </el-col>
        <el-col :span="24" class="marginTB20">
          <!-- 折线图 -->
          <CircularChart
            class="graphShadow paddingT10 paddingL20"
            :nightParktableData="tableData"
            :type="type3"
          ></CircularChart>
        </el-col>
        <el-col :span="24">
          <!-- 折线图 -->
          <cityChart
            class="graphShadow paddingT10 paddingL20"
            :berTwo="berthSaturation"
            @getData="getData"
            :NumberTwo="NumberTwo"
            :time="daytwo"
          ></cityChart>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import // exportExcelNew
// dateFormat
//   setIndex
"@/common/js/public.js";
import autoComplete from "@/components/autocomplete";
// 折线图
import CircularChart from "./cityChart.vue";
import cityChart from "./cityChartTwo.vue";
export default {
  name: "parkIncomeDayStatics",
  data() {
    let date = new Date();
    date.setHours(-23);
    date.setMinutes(-59);
    date.setSeconds(-59);
    let dateTime = date;
    return {
      NumberTwo: 0,
      berthSaturation: [],
      areaId: "",
      streetId: "",
      areaList: [],
      streetList: [],
      daytwo: "",
      parkData: [],
      // 折线图样式
      type3: "c3",
      type2: "c2",
      arrearageStrip: [],
      temParkData: {},
      modelvalue: "",
      tableData: [],
      city: [],
      pName: "",
      index: 0,
      page: 1,
      startDate: "",
      endDate: "",
      pageSize: 15,
      total: 0,
      loading: false,
      detailFlag: false,
      formInline: {
        parkId: "",
        date: dateTime,
      },
      selectkeys: ["date", "month", "week", "year"],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > dateTime;
        },
      },
      timeInterval: {},
    };
  },
  watch: {
    modelvalue(newVal) {
      if (!newVal) {
        this.formInline.parkId = "";
        this.temParkData = {};
      }
    },
  },
  methods: {
    parkClear() {
      this.$refs.parkSelect.clear();
    },
    empty() {
      this.areaId = "";
      this.modelvalue = "";
      this.streetId = "";
      this.formInline.date = new Date();
      this.streetList = [];
      this.$refs.cascader.clear();
      this.$refs.operationSelect.clear();
      this.$refs.datePicker.clear();
      this.$refs.parkTypeSelect.clear();
      this.$refs.parkSelect.clear();
    },
    getAreaList() {
      let url = "/acb/2.0/systems/loginUser/initAreaList";
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          if (res.value.depth == 3) {
            this.areaList = res.value.areaList;
          } else {
            this.streetList = res.value.areaList;
          }
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    setParkNull() {
      this.formInline.parkId = "";
      this.modelvalue = "";
      this.temParkData = {};
    },
    areaChange(areaId) {
      this.parkPage = 1;
      this.streetList = [];
      this.streetId = "";
      this.setParkNull();
      if (!areaId) return;
      this.getStreet(areaId);
    },
    streetChange(streetId) {
      this.parkPage = 1;
      this.setParkNull();
      this.getPark(streetId);
    },
    // 请求区域数据
    getStreet(areaId) {
      let url = "/acb/2.0/systems/loginUser/getChildrenArea";
      this.$axios
        .get(url, {
          data: {
            areaId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.streetList = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    getPark(areaId) {
      this.area = areaId;
      let url = "/acb/2.0/systems/loginUser/getParkName";
      this.$axios
        .get(url, {
          data: {
            page: this.parkPage,
            pageSize: this.pageSize,
            areaIds: areaId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            if (this.parkPage == 1) {
              this.$refs.select.setNull();
            }
            this.parkData = res.value.list;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    querySearchAsync(queryString, cb) {
      this.$emit("valueChange", "");
      let areaId = "";
      if (this.$refs.cascader) {
        areaId = this.$refs.cascader.getStreetId()
          ? this.$refs.cascader.getStreetId()
          : this.$refs.cascader.getAreaId();
      }
      queryString = queryString.replace(/\s/g, "");
      let url = "/acb/2.0/systems/loginUser/getParkName";
      this.$axios
        .get(url, {
          data: {
            page: 1,
            pageSize: 15,
            parkName: queryString,
            slaveRelations: "0,1",
            areaIds: areaId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            // this.parkData = res.value.list;
            this.temParkData = res.value.list.length
              ? res.value.list[0]
              : { parkName: "", parkId: "" };
            this.formInline.parkId = "";
            cb(res.value.list);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    handleSelect(item) {
      this.formInline.parkId = item.parkId;
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getData();
    },
    getData(val) {
      // if (!this.formInline.date) {
      //   this.$alert("日期不能为空", "提示");
      //   return;
      // }
      if (val === 1) {
        this.NumberTwo = 1;
      } else if (val === 2) {
        this.NumberTwo = 2;
      } else {
        this.NumberTwo = 2;
      }
      // this.daytwo = this.formInline.date;
      // // localStorage.clear()
      // localStorage.timeBy = this.formInline.date;
      // let timeOne = this.formInline.date;
      // let year = timeOne.getFullYear();
      // let month = timeOne.getMonth() + 1;
      // let day = timeOne.getDate();
      // if (month < 10) {
      //   month = "0" + month;
      // }
      // if (day < 10) {
      //   day = "0" + day;
      // }
      // var Timedata = year + "-" + month + "-" + day;
      if (!this.formInline.parkId && Object.keys(this.temParkData).length) {
        this.modelvalue = this.temParkData.parkName;
        this.formInline.parkId = this.temParkData.parkId;
        this.temParkData = {};
      }
      let url = "/acb/2.0/aceParkAnalysis/interval";
      this.loading = true;
      this.$axios
        .post(url, {
          data: {
            dataType: val || "2",
            streetId: this.$refs.cascader ? this.$refs.cascader.getStreetId() : "",
            areaId: this.$refs.cascader ? this.$refs.cascader.getAreaId() : "",
            dataSource: this.$refs.parkTypeSelect ? this.$refs.parkTypeSelect.getParkType() : "",
            operationId: this.$refs.operationSelect
              ? this.$refs.operationSelect.getOperationId()
              : "",
            parkId: this.$refs.parkSelect ? this.$refs.parkSelect.getParkId() : "",
            dateType: this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "",
            startDate: this.$refs.datePicker ? this.$refs.datePicker.getStartDate() : "",
            endDate: this.$refs.datePicker ? this.$refs.datePicker.getEndDate() : "",
          },
        })
        .then((res) => {
          this.loading = false;
          // 车辆进出趋势
          this.tableData = res.value.parkHourDistribute;
          // 停车饱和度分析
          this.berthSaturation =
            res.value.berthSaturation == null ? [{}, {}, {}] : res.value.berthSaturation;
          // 停车时段
          this.timeInterval = res.value.peakHour;
        })
        .finally(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    receiveData(data) {
      this.formInline.parkId = data.value.parkId;
    },
    comHour(arr, replacement) {
      if (arr && arr.length > 0) {
        return arr
          .map((res) => {
            return res.startTime + "-" + res.endTime;
          })
          .join("\n");
      } else {
        return replacement;
      }
    },
  },
  beforeDestroy() {},
  components: {
    CircularChart,
    cityChart,
  },

  created() {
    // this.getParkList();
    // this.getTimeNow();
    this.getAreaList();
  },
  computed: {
    comPeakHour() {
      if (this.timeInterval.peakHour) {
        let arr = this.timeInterval.peakHour.split(",");
        if (arr.length > 2) {
          return arr[0] + "," + arr[1];
        } else {
          return this.timeInterval.peakHour;
        }
      } else {
        return "暂无停车高峰时段";
      }
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb {
  height: 35px;
}

.parkStatisticbg1 {
  background: #68ce62;
}

.parkStatisticbg2 {
  background: #e6a23c;
}

.parkStatisticbg3 {
  background: #3a8ee6;
}

.parkStatisticbg4 {
  background: #FF3300;
}

.timeBox {
  width: 100%;
  height: auto;
  margin-top: 10px;
}

.title {
  text-align: center;
  line-height: 40px;
  font-weight: 800;
  font-size: 19px;
}

.Colorbox {
  height: 120px;
  background: #f60;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Colorbox h2 {
  white-space: pre-wrap;
  color: white;
  font-size: 18px;
}

.parkStatistic {
  width: 150px;
  height: 150px;
  line-height: 150px;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
  margin-bottom: 40px;
  color: #fff;

  span {
    font-size: 40px;
  }
}

.content {
  overflow: hidden;

  .searchWrapper {
    .list {
      margin-top: 20px;

      .el-col {
        text-align: center;
        padding-bottom: 18px;

        .grid-content {
          padding-top: 15px;
          height: 100%;
        }

        h2 {
          font-size: 18px;
          text-align: center;
        }
      }
    }
  }

  .pagerWrapper {
    text-align: right;
    margin-top: 28px;
    font-size: 12px;

    .export {
      font-size: 12px;

      .iconfont {
        margin-right: 0;
      }
    }
  }
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.el-dropdown-link {
  cursor: pointer;
}
</style>
